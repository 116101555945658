import React, { Component } from "react";

import blog_detail from "../assets/v2/images/blog_detail.jpg";
import blog_list1 from "../assets/v2/images/blog_list1.jpg";
import long_arrow_right from "../assets/v2/images/long_arrow_right.svg";
import blog_list2 from "../assets/v2/images/blog_list2.jpg";
import blog_list3 from "../assets/v2/images/blog_list3.jpg";

export class Blog_detail extends Component {
  render() {
    return (
      <section class="dark">
        <div class="blog_detail">
          <div class="container">
            <a href="#" class="back_link">
              Back to blogs
            </a>
            <h2 class="global_title white">
              <strong>
                <span>Blog</span> /
              </strong>{" "}
              Faria Setiawan
            </h2>
            <figure>
              <img src={blog_detail} alt="" />
            </figure>
          </div>
          <div class="container small_cont">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.{" "}
            </p>
            <p>
              Duis aute irure dolor in reprehenderit in voluptate velit esse
              cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
              cupidatat non proident, sunt in culpa qui officia deserunt mollit
              anim id est laborum.
            </p>
            <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h3>
            <p>
              Duis aute irure dolor in reprehenderit in voluptate velit esse
              cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
              cupidatat non proident, sunt in culpa qui officia deserunt mollit
              anim id est laborum.
            </p>
            <p>
              Duis aute irure dolor in reprehenderit in voluptate velit esse
              cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
              cupidatat non proident, sunt in culpa qui officia deserunt mollit
              anim id est laborum.
            </p>
            <p>
              Duis aute irure dolor in reprehenderit in voluptate velit esse
              cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
              cupidatat non proident, sunt in culpa qui officia deserunt mollit
              anim id est laborum.
            </p>
            <h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h4>
            <p>
              Duis aute irure dolor in reprehenderit in voluptate velit esse
              cillum dolore eu fugiat nulla pariatur.
            </p>
            <ol>
              <li>
                Duis aute irure dolor in reprehenderit in voluptate velit esse
                cillum dolore eu fugiat nulla pariatur.
              </li>
              <li>
                Duis aute irure dolor in reprehenderit in voluptate velit esse
                cillum dolore eu fugiat nulla pariatur.
              </li>
              <li>
                Duis aute irure dolor in reprehenderit in voluptate velit esse
                cillum dolore eu fugiat nulla pariatur.
              </li>
              <li>
                Duis aute irure dolor in reprehenderit in voluptate velit esse
                cillum dolore eu fugiat nulla pariatur.
              </li>
            </ol>
            <div class="share_info">
              <h4>Share</h4>
              <ul class="social_info">
                <li>
                  <a href="#">
                    <i class="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="fa fa-linkedin"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="fa fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="fa fa-twitter"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="related_sec">
          <div class="container">
            <h4>Related Articles</h4>
            <div class="card-columns blog-card-columns">
              <div class="card">
                <figure>
                  <a href="/blog_cover">
                    <img src={blog_list1} alt="" />
                  </a>
                </figure>
                <div class="detail">
                  <h3>
                    <a href="/blog_cover">Faria Setiawan</a>
                  </h3>
                  <span>Develoter</span>

                  <a href="/blog_cover" class="more_link">
                    <img src={long_arrow_right} alt="" />
                  </a>
                </div>
              </div>
              <div class="card">
                <figure>
                  <a href="/blog_cover">
                    <img src={blog_list2} alt="" />
                  </a>
                </figure>
                <div class="detail">
                  <h3>
                    <a href="/blog_cover">Faria Setiawan</a>
                  </h3>
                  <span>Develoter</span>

                  <a href="/blog_cover" class="more_link">
                    <img src={long_arrow_right} alt="" />
                  </a>
                </div>
              </div>
              <div class="card">
                <figure>
                  <a href="/blog_cover">
                    <img src={blog_list3} alt="" />
                  </a>
                </figure>
                <div class="detail">
                  <h3>
                    <a href="/blog_cover">Faria Setiawan</a>
                  </h3>
                  <span>Develoter</span>

                  <a href="/blog_cover" class="more_link">
                    <img src={long_arrow_right} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Blog_detail;
