import React, { Component } from "react";
import buyshubhbanner from "../assets/v2/images/buyshubhbanner.png";
import processed from "../assets/v2/images/processed.png";
import buyshubhlastbanner from "../assets/v2/images/buyshubhlastbanner.png";
import { Modal, Image, Row, Col } from "react-bootstrap";
import Transactions from "../assets/v2/images/Transactions.png";
import Verification from "../assets/v2/images/Verification.png";
import panca from "../assets/v2/images/panca.png";
import Uniswap from "../assets/v2/images/Uniswap.png";
import eriumwallet from "../assets/v2/images/eriumwallet.png";
import $ from "jquery";

class Buyshubh extends Component {
  constructor() {
    super();
    this.state = {
      show: false,
    };
  }

  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  render() {
    return (
      <section>
        <>
          <Modal show={this.state.show} onHide={this.handleClose}>
            <Modal.Header
              closeButton
              style={{ backgroundColor: "#024AAD" }}
            ></Modal.Header>
            <Modal.Body
              style={{
                padding: "3px !important",
                backgroundColor: "#024AAD",
                justifyContent: "center",
              }}
            >
              <div class="text-center mt-4">
                <h4 style={{ color: "#ffffff" }}>How To Buy SHUBH</h4>
                <div
                  style={{
                    border: "1px solid #4891f7",
                    width: "100px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                ></div>
                <p class="mt-4" style={{ color: "#ffffff" }}>
                  The native token Shubh will be available for purchase on the
                  following exchanges and <br></br> OTC desks to pay for network
                  transaction fees.
                </p>
                {/* <div class="row">
                  <div class="col-md-2 col-lg-2 col-sm-12"></div>
                  <div class="col-md-8 col-lg-8 col-sm-12 mt-5">
                    <p style={{ color: "#ffffff", fontSize: 13 }}>
                      The enterprise-grade public network of shubh gives a
                      higher degree of security, speed, and scalability for a
                      multitude of business processes.SHUBH is the native
                      cryptocurrency of Shubh Network that is used to validate
                      all transactions and processes across the network. Whether
                      you're a startup or enterprise, a creator or consumer,
                      Shubh goes beyond blockchain for developers to create the
                      next generation of fast, fair, and secure applications.
                    </p>
                  </div>
                  <div class="col-md-2 col-lg-2 col-sm-12"></div>
                </div> */}
              </div>
              <div class="row mt-5 mb-3">
                <div class="col-md-2 col-lg-2 col-sm-12"></div>
                <div class="col-md-8 col-lg-8 col-sm-12 row">
                  <div class="col-md-4 col-lg-4 col-sm-12  ">
                    <a
                      style={{
                        border: "1px solid #ffffff",
                        borderRadius: 5,
                        justifyContent: "center",
                        alignItems: "center",
                        height: 64,
                        width: 200,
                      }}
                      class="row"
                      href="https://app.uniswap.org/#/swap?inputCurrency=0x2c8aec2fd0c0feba71e0424873461908067d5bf4&chain=mainnet"
                    >
                      <div class="mr-2">
                        <img src={Uniswap} />
                      </div>
                      <div>
                        <p
                          style={{
                            color: "#ffffff",
                            fontSize: 13,
                            marginTop: 17,
                          }}
                        >
                          Uniswap
                        </p>
                      </div>
                    </a>
                  </div>
                  <div class="col-md-4 col-lg-4 col-sm-12  ">
                    <a
                      style={{
                        border: "1px solid #ffffff",
                        borderRadius: 5,
                        justifyContent: "center",
                        alignItems: "center",
                        width: 200,
                      }}
                      class="row"
                      href="https://pancakeswap.finance/swap/0x0a13898E7377ea873bFea6e29B78abf6df91b6f1"
                    >
                      <div class="mr-2">
                        <img src={panca} />
                      </div>
                      <div>
                        <p
                          style={{
                            color: "#ffffff",
                            fontSize: 13,
                            marginTop: 20,
                          }}
                        >
                          Pancakeswap
                        </p>
                      </div>
                    </a>
                  </div>
                  <div class="col-md-4 col-lg-4 col-sm-12  ">
                    <a
                      style={{
                        border: "1px solid #ffffff",
                        borderRadius: 5,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      className="row"
                      href="https://play.google.com/store/apps/details?id=io.horizontalsystems.erium"
                    >
                      <div class="mr-2">
                        <img src={eriumwallet} />
                      </div>
                      <div>
                        <p
                          style={{
                            color: "#ffffff",
                            fontSize: 13,
                            marginTop: 20,
                          }}
                        >
                          Third Party Wallet Application
                        </p>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="col-md-2 col-lg-2 col-sm-12"></div>
              </div>
            </Modal.Body>
          </Modal>
        </>
        <div class="hero_sec ">
          <div class="container">
            <div class="row no-gutters align-items-center">
              <div class="col-xl-1 col-lg-1 col-md-1 col-sm-12 "></div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 ">
                <div class="detail">
                  <h2 style={{ marginTop: "100px", color: "#024AAD" }}>
                    What is SHUBH?
                  </h2>
                  <p class="mt-4" style={{ fontSize: "20px" }}>
                    SHUBH is the one token that links all other blockchains.
                    Designed to optimize trading times and minimize transaction
                    costs. SHUBH is the future of currency.
                  </p>

                  <div class="btns" onClick={this.handleShow}>
                    Get Shubh
                  </div>
                  <div class="clearfix"></div>
                  {/* <a href="#intentSection" class="scroll_down"><img src="images/long_arrow_down.svg" alt=""></a> */}
                </div>
              </div>
              <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                <img
                  src={buyshubhbanner}
                  alt=""
                  style={{ height: "350px", width: "450px" }}
                />
              </div>
            </div>
          </div>
        </div>

        <div class="welcome_sec mt-5">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-xl-7 col-lg-8 col-md-8 col-sm-12">
                <div class="detail">
                  <h1>Why SHUBH tokens?</h1>
                  <p style={{ fontSize: "18px", color: "#B0B0B0" }}>
                    Any user, located anywhere in the world, can use SHUBH as a
                    secure exchange of value – without requiring a third party
                    to mediate the exchange. Every transaction using SHUBH has a
                    permanent, secure, and visible record on the Shubh Network.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="hero_sec">
          <div class="container">
            <div class="col-lg-3"></div>
            <div class="col-lg-6 ml-5">
              <h4>Shubh Tokens' Importance to the Blockchain</h4>
            </div>
            <div class="col-lg-3"></div>
            <div class="row  mt-5">
              <div class="col-lg-1"></div>
              <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                <div class="built_block2">
                  <img src={Verification} alt="" />
                  <div class="detail">
                    <p
                      style={{
                        fontSize: "20px",
                        color: "#024AAD",
                        marginTop: "35px",
                        marginBottom: "0px",
                      }}
                    >
                      Verification Cost
                    </p>
                    <p style={{ fontSize: "16px" }}>
                      The SHUBH token is used to pay fees for transactions
                      taking place on all DApps created and running on the Shubh
                      Network.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                <div class="built_block2">
                  <img src={Transactions} alt="" />
                  <div class="detail">
                    <p
                      style={{
                        fontSize: "20px",
                        color: "#024AAD",
                        marginTop: "35px",
                        marginBottom: "0px",
                      }}
                    >
                      Staking of Transactions
                    </p>
                    <p style={{ fontSize: "16px" }}>
                      Shubh holders validate all transactions and processes
                      across the network by staking their tokens to verify
                      contracts, and thus maintain the network.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-1"></div>
            </div>
          </div>
        </div>

        <div class="welcome_sec mt-5">
          <div class="container">
            <div class="row flex-row-reverse align-items-center">
              <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                <div class="detail">
                  <h3 style={{ fontSize: "20px", color: "#024AAD" }}>
                    Where Can I Get SHUBH?
                  </h3>
                  <p style={{ fontSize: "16px" }}>
                    SHUBH is available on many of the leading centralized and
                    decentralized exchanges around the world. Visit to see the
                    list of exchanges that support SHUBH.
                    <p class="mt-2" style={{ fontSize: "16px" }}>
                      Alternatively you can get SHUBH via Direct
                      Deposits/Withdrawals to the Shubh Network.
                    </p>
                  </p>
                </div>

                <a class="btns mt-5" onClick={this.handleShow}>
                  Get Shubh
                </a>
              </div>
              <div class="col-xl-5 col-lg-5 col-md-2 col-sm-12">
                <figure>
                  <img src={processed} alt="" />
                </figure>
              </div>
            </div>

            <div class="row align-items-center">
              <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                <div class="detail">
                  <h3 style={{ fontSize: "20px", color: "#024AAD" }}>
                    How Do I Store & Transfer My SHUBH?
                  </h3>
                  <p style={{ fontSize: "16px" }}>
                    A cryptocurrency wallet is a software program designed to
                    store your public and private keys, send and receive digital
                    currencies, monitor your balance, and interact with
                    supported blockchains.
                  </p>
                  <p class="mt-2" style={{ fontSize: "16px" }}>
                    SHUBH tokens are supported by all major crypto wallets.
                    Click here to create a free wallet if you are a new crypto
                    investor.
                  </p>
                </div>

                <a href="#" class="btns mt-5">
                  Create Wallet
                </a>
              </div>
              <div class="col-xl-5 col-lg-5 col-md-3 col-sm-12">
                <figure class="text-right">
                  <img src={buyshubhlastbanner} alt="" />
                </figure>
              </div>
              {/* <div class="col-xl-1 col-lg-2 col-md-2 col-sm-12"></div> */}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Buyshubh;
