import React, { Component } from "react";

import news_image1 from '../assets/v2/images/news_image1.jpg'
import news_image2 from '../assets/v2/images/news_image2.jpg'
import news_image3 from '../assets/v2/images/news_image3.jpg'
import news_image4 from '../assets/v2/images/news_image4.jpg'

class News extends Component {
  render() {
    return (
      <section>
        <div class="news_sec">
          <div class="container">
            <div class="row mb-100 specing17">
              <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                <div class="news_block1">
                  <figure>
                    <img src={news_image1} alt="" />
                  </figure>
                  <div class="detail">
                    <h3>
                      <a href="#">Faria Setiawan</a>
                    </h3>
                    <span>Develoter</span>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                <div class="news_block1">
                  <figure>
                    <img src={news_image1} alt="" />
                  </figure>
                  <div class="detail">
                    <h3>
                      <a href="#">Faria Setiawan</a>
                    </h3>
                    <span>Develoter</span>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                <div class="news_block1">
                  <figure>
                    <img src={news_image1} alt="" />
                  </figure>
                  <div class="detail">
                    <h3>
                      <a href="#">Faria Setiawan</a>
                    </h3>
                    <span>Develoter</span>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                <div class="news_block1 news_block2">
                  <figure>
                    <img src={news_image2} alt="" />
                  </figure>
                  <div class="detail">
                    <h3>
                      <a href="#">Faria Setiawan</a>
                    </h3>
                    <span>Develoter</span>
                  </div>
                </div>
                <div class="news_block1 news_block2">
                  <figure>
                    <img src={news_image2} alt="" />
                  </figure>
                  <div class="detail">
                    <h3>
                      <a href="#">Faria Setiawan</a>
                    </h3>
                    <span>Develoter</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-120 specing17">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div class="news_block1 news_block3">
                  <figure>
                    <img src={news_image3} alt="" />
                  </figure>
                  <div class="detail">
                    <h3>
                      <a href="#">Faria Setiawan</a>
                    </h3>
                    <span>Develoter</span>
                  </div>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div class="news_block1 news_block3">
                  <figure>
                    <img src={news_image3} alt="" />
                  </figure>
                  <div class="detail">
                    <h3>
                      <a href="#">Faria Setiawan</a>
                    </h3>
                    <span>Develoter</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-80 specing17">
              <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div class="news_block4">
                  <figure>
                    <a href="#">
                      <img src={news_image4} alt="" />
                    </a>
                  </figure>
                  <div class="detail">
                    <h3>
                      <a href="#">Faria Setiawan</a>
                    </h3>
                    <span>Develoter</span>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div class="news_block4">
                  <figure>
                    <a href="#">
                      <img src={news_image4} alt="" />
                    </a>
                  </figure>
                  <div class="detail">
                    <h3>
                      <a href="#">Faria Setiawan</a>
                    </h3>
                    <span>Develoter</span>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div class="news_block4">
                  <figure>
                    <a href="#">
                      <img src={news_image4} alt="" />
                    </a>
                  </figure>
                  <div class="detail">
                    <h3>
                      <a href="#">Faria Setiawan</a>
                    </h3>
                    <span>Develoter</span>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="row specing17">
              <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                <div class="news_block1">
                  <figure>
                    <img src={news_image1} alt="" />
                  </figure>
                  <div class="detail">
                    <h3>
                      <a href="#">Faria Setiawan</a>
                    </h3>
                    <span>Develoter</span>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                <div class="news_block1">
                  <figure>
                    <img src={news_image1} alt="" />
                  </figure>
                  <div class="detail">
                    <h3>
                      <a href="#">Faria Setiawan</a>
                    </h3>
                    <span>Develoter</span>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                <div class="news_block1">
                  <figure>
                    <img src={news_image1} alt="" />
                  </figure>
                  <div class="detail">
                    <h3>
                      <a href="#">Faria Setiawan</a>
                    </h3>
                    <span>Develoter</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default News;
