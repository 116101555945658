import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation,
} from "react-router-dom";
import Logo from "../assets/v2/images/logo.svg";
import Implemetation from "./Implementation.js";
import Implemetation5 from "./Implementation5.js";
import Home from "./Home2.js";
import Team from "./Team";
import News from "./News";
import JoinUs from "./JoinUs";
import SignUp from "./SignUp";
import $ from "jquery";
import Buyshubh from "./Buyshubh";
// import ReactGA from "react-ga";
import ReactGA from "react-ga4";
import { createBrowserHistory } from "history";

ReactGA.initialize("G-SB964SZ5K7");
const history = createBrowserHistory();
history.listen((location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.send(location.pathname);
});

const location = window.location;

const { pathname } = location;

// console.log(pathname);
// const splitLocation = location.split("/");
// console.log(splitLocation);
export class Header extends Component {
  constructor() {
    super();
    // ReactGA.initialize("G-SB964SZ5K7"); //Old
  }

  // setGA = () => {
  //   console.log("setga");
  //   ReactGA.initialize("G-SB964SZ5K7", { debug: true });
  //   ReactGA.send(window.location.pathname + window.location.search);
  //   // ReactGA.initialize("G-SB964SZ5K7"); //New
  //   // ReactGA.pageview(window.location.pathname + window.location.search);
  // };
  // setGA = () => {
  //   console.log("setga");
  //   ReactGA.initialize("UA-209660118-1", { debug: true }); //New
  //   // ReactGA.initialize("G-SB964SZ5K7"); //New
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // };

  componentDidMount() {
    // this.setGA();
    $(".scroll_down2").click(function () {
      console.log("click");
      var sectionTo = $(this).attr("href");
      $("html, body").animate(
        {
          scrollTop: $(sectionTo).offset().top,
        },
        500
      );
    });
  }
  render() {
    return (
      <Router>
        <header>
          <div class="container">
            <div class="nav_sec">
              <nav class="navbar navbar-expand-lg navbar-light">
                <a class="navbar-brand" href="/">
                  <img src={Logo} alt="" />
                </a>
                <button
                  class="navbar-toggler navbar-toggler-right collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#Navigation"
                  aria-controls="Navigation"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>

                <div class="collapse navbar-collapse" id="Navigation">
                  <ul class="navbar-nav ml-auto">
                    <li className={pathname === "/home" ? "active" : ""}>
                      <a class="nav-link" href="/home">
                        Home
                      </a>
                    </li>
                    <li class="nav-item ">
                      <a
                        class="nav-link scroll_down2"
                        href="/home#intent_section"
                        // id="intent_section"
                        // role="button"
                        // data-toggle="dropdown"
                        // aria-haspopup="true"
                        // aria-expanded="false"
                      >
                        Shubh Network
                      </a>
                      {/* <div
                        class="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <a class="dropdown-item" href="#">
                          Action
                        </a>
                        <a class="dropdown-item" href="#">
                          Another action
                        </a>
                        <a class="dropdown-item" href="#">
                          Something else
                        </a>
                        <a class="dropdown-item" href="#">
                          Action
                        </a>
                      </div> */}
                    </li>
                    <li
                      className={pathname === "/implemetation" ? "active" : ""}
                    >
                      <a class="nav-link" href="/implemetation">
                        Implementation
                      </a>
                    </li>
                    {/* <li class="nav-item">
                      <a class="nav-link" href="/implemetation">
                        Implementation
                      </a>
                    </li> */}
                    <li className={pathname === "/team" ? "active" : ""}>
                      <a class="nav-link" href="/team">
                        Team
                      </a>
                    </li>

                    <li className={pathname === "/forum" ? "active" : ""}>
                      <a class="nav-link" href="/forum">
                        News
                      </a>
                    </li>
                    <li className={pathname === "/contact" ? "active" : ""}>
                      <a class="nav-link" href="/contact">
                        Support
                      </a>
                    </li>
                    {/* <li class="nav-item get_btn">
                      <a class="" href="/joinus">
                        Get started
                      </a>
                    </li> */}
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </header>
        <Switch>
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
          <Route path="/implemetation5">
            <Implemetation5 />
          </Route>
          <Route path="/implemetation">
            <Implemetation />
          </Route>
          <Route path="/home">
            <Home />
          </Route>
          <Route path="/buyshubh">
            <Buyshubh />
          </Route>
          <Route path="/team">
            <Team />
          </Route>
          <Route path="/news">
            <News />
          </Route>
          <Route path="/joinus">
            <JoinUs />
          </Route>
          <Route path="/sign_up">
            <SignUp />
          </Route>

          {/* <Route path="/contact"><ContactUs /></Route>
                    <Route path="/about"><AboutUs /></Route>
                    <Route path="/feature"><Feature /></Route>
                    <Route path="/faq"><Faq /></Route>
                    <Route path="/blog"><BlogComponent /></Route> */}
        </Switch>
      </Router>
    );
  }
}

export default Header;
