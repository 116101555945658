import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";

const recaptchaRef = React.createRef();
class Login extends Component {
  constructor() {
    super();

    this.state = {
      email: "",
      password: "",

      emailError: null,
      passwordError: null,
    };
  }

  validate() {
    let emailError = "";
    let passwordError = "";

    if (!this.state.email) {
      emailError = "Your email cannot be empty";
    }
    if (!this.state.password) {
      passwordError = "Your email cannot be empty";
    }

    if (emailError || passwordError) {
      this.setState({
        emailError,
        passwordError,
      });
      return false;
    }
    return true;
  }

  onSubmit = () => {
    if (this.validate()) {
      this.login();
    }
  };

  storeData = (value) => {
    try {
      localStorage.setItem("token", value);
      console.log("token", value);
    } catch (e) {
      // saving error
    }
  };

  login = async () => {
    this.setState({ loading: true });

    const formData = new FormData();
    formData.append("email", this.state.email);
    formData.append("password", this.state.password);

    const recaptchaValue = recaptchaRef.current.getValue();

    if (!recaptchaValue.length) {
      alert("Please Check Captcha");
      // this.setState({ reply: "You must not be a robot!" });
      return;
    }

    fetch("https://shubh.network/shubhbackend/api/login", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ loading: false });
        if (data) {
          this.storeData(data.success.token);
          // this.storeEmail(this.state.email);
          // toast(data.message);
        } else {
          // toast(data.message);
        }
        alert("Login Successful");
        window.location.href = "/home";
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  render() {
    return (
      <section>
        <div class="hero_sec">
          <form>
            <div className="row ">
              <div class="col-sm-4"></div>
              <div class="col-sm-4">
                <div class="text-center mb-4">
                  <h4>Login</h4>
                </div>
                <div class="form-group">
                  <input
                    className="input form-control"
                    type="text"
                    name="email"
                    onChange={(event) =>
                      this.setState({ email: event.target.value })
                    }
                    required
                    aria-describedby="emailHelp"
                    placeholder="email"
                  />
                  {!!this.state.emailError && (
                    <text style={{ color: "red" }}>
                      {this.state.emailError}
                    </text>
                  )}
                </div>
                <div class="form-group">
                  <input
                    className="input form-control"
                    type="password"
                    name="password"
                    onChange={(event) =>
                      this.setState({ password: event.target.value })
                    }
                    required
                    aria-describedby="emailHelp"
                    placeholder="password"
                  />
                  {!!this.state.passwordError && (
                    <text style={{ color: "red" }}>
                      {this.state.passwordError}
                    </text>
                  )}
                </div>
                <ReCAPTCHA
                  sitekey="6Ld0HmsgAAAAAIaI3ZchWCm4f7n4WUEmY5wrx1lR"
                  ref={recaptchaRef}
                  className="mb-5"
                />
                {!!this.state.captchError && (
                  <text style={{ color: "red", marginTop: "-10px" }}>
                    {this.state.captchError}
                  </text>
                )}

                <div className="col-lg-6 col-xl-6 col-sm-12 col-md-6 offset-3">
                  <button
                    // href="#"
                    type="button"
                    class="btns"
                    onClick={this.onSubmit}
                  >
                    Login
                  </button>
                </div>
              </div>
              <div class="col-sm-4"></div>
            </div>
          </form>
        </div>
      </section>
    );
  }
}

export default Login;
