import React, { Component } from "react";

import blog_detail from "../assets/v2/images/blog_detail.jpg";
import Instagram from "../assets/v2/images/Instagram.svg";
import LinkedIn from "../assets/v2/images/LinkedIn.svg";
import twitter from "../assets/v2/images/twitter.svg";
import parse from "html-react-parser";

export class Blog_inner extends Component {
  constructor() {
    super();
    var query = window.location.search.substring(1);
    var vars = query.split("=");
    var id = vars[1];
    this.getBlogDetails(id);
    this.state = {
      blog: [],
      comment: "",
    };
  }
  getBlogDetails = (id) => {
    this.setState({ loading: true });

    fetch("https://shubh.network/shubhbackend/api/blog_detail/" + id, {
      method: "GET",
      // body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          blog: data.blogs,
        });
        // alert('Email sent successfully we will contact you soon')
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  storeData = (value) => {
    try {
      localStorage.setItem("comment", value);
      console.log("comment", value);
    } catch (e) {
      // saving error
    }
  };
  onSubmit = () => {
    console.log("token----",localStorage.getItem("token"))
    if(localStorage.getItem("token") != null){
        this.comment();
    } else {
      window.location.href="/login";
    }
  }
  comment = () => {
    this.setState({ loading: true });

    const formData = new FormData();
    formData.append("comment", this.state.comment);
    // console.log(this.state.comment);
    fetch("https://shubh.network/shubhbackend/api/comment", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('data',data);
        console.log('comment-------',this.state.comment);
        this.setState({ loading: false });
        if (data.success == "true") {
          this.storeData(data.Comment.comment);
          // console.log('inside class', data.Comment.comment)
        } else {
          // toast(data.message);
        }
        // alert("Thank You for Register");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  render() {
    return (
      <section>
        <div class="blog_detail">
          <div class="container">
            <figure>
              <img src={this.state.blog.feature_image} alt="" />
            </figure>
          </div>
          <div class="container small_cont">
            {parse(this.state.blog.content ? this.state.blog.content : "")}
          </div>
        </div>

        {/* <div class="container">
          <div class="row">
            <div class="col-lg-1"></div>
            <div class="col-lg-10">
             
              <h5>Comments</h5>
              <textarea
                class="form-control py-0 px-1 border-0 mt-3"
                rows="3"
                placeholder="Start writing..."
                style={{ marginBottom: "20px" }}
                onChange={(event) =>
                  this.setState({ comment: event.target.value })
                }
              ></textarea>
              <a onClick={this.comment}>
                <div
                style={{
                  width: "100px",
                  backgroundColor: "#024AAD",
                }}
              >
                <h4 style={{ textAlign: "center",padding:'6px',color:'#ffffff' }}>Post</h4>
              </div>
              </a>

            </div>
            <div class="col-lg-1"></div>
           
          </div>
        </div> */}

        <div class="blog_detail">
          <div class="d-flex align-items-center justify-content-center vh-100">
            <div class="container">
              <div class="row justify-content-center mb-4">
                <div class="col-lg-8">
                  <h5>2 Comments</h5>
                </div>
              </div>
              <div class="row justify-content-center mb-4">
                <div class="col-lg-8">
                  <div class="comments">
                    <div class="comment d-flex mb-4">
                      <div class="flex-shrink-0">
                        <div class="avatar avatar-sm rounded-circle">
                          <img
                            class="avatar-img"
                            src="https://uifaces.co/our-content/donated/AW-rdWlG.jpg"
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="flex-grow-1 ms-2 ms-sm-3">
                        <div class="comment-meta d-flex align-items-baseline">
                          <h6 class="me-2">Jordan Singer</h6>
                          <span class="text-muted">2d</span>
                        </div>
                        <div class="comment-body">
                          Lorem ipsum, dolor sit amet consectetur adipisicing
                          elit. Non minima ipsum at amet doloremque qui magni,
                          placeat deserunt pariatur itaque laudantium impedit
                          aliquam eligendi repellendus excepturi quibusdam nobis
                          esse accusantium.
                        </div>

                        <div class="comment-replies bg-light p-3 mt-3 rounded">
                          <h6 class="comment-replies-title mb-4 text-muted text-uppercase">
                            2 replies
                          </h6>

                          <div class="reply d-flex mb-4">
                            <div class="flex-shrink-0">
                              <div class="avatar avatar-sm rounded-circle">
                                <img
                                  class="avatar-img"
                                  src="https://images.unsplash.com/photo-1501325087108-ae3ee3fad52f?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=f7f448c2a70154ef85786cf3e4581e4b"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div class="flex-grow-1 ms-2 ms-sm-3">
                              <div class="reply-meta d-flex align-items-baseline">
                                <h6 class="mb-0 me-2">Brandon Smith</h6>
                                <span class="text-muted">2d</span>
                              </div>
                              <div class="reply-body">
                                Lorem ipsum dolor sit, amet consectetur
                                adipisicing elit.
                              </div>
                            </div>
                          </div>
                          <div class="reply d-flex">
                            <div class="flex-shrink-0">
                              <div class="avatar avatar-sm rounded-circle">
                                <img
                                  class="avatar-img"
                                  src="https://uifaces.co/our-content/donated/6f6p85he.jpg"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div class="flex-grow-1 ms-2 ms-sm-3">
                              <div class="reply-meta d-flex align-items-baseline">
                                <h6 class="mb-0 me-2">James Parsons</h6>
                                <span class="text-muted">1d</span>
                              </div>
                              <div class="reply-body">
                                Lorem ipsum dolor sit amet, consectetur
                                adipisicing elit. Distinctio dolore sed eos
                                sapiente, praesentium.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="comment d-flex">
                      <div class="flex-shrink-0">
                        <div class="avatar avatar-sm rounded-circle">
                          <img
                            class="avatar-img"
                            src="https://uifaces.co/our-content/donated/EPx48HPQ.jpg"
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="flex-shrink-1 ms-2 ms-sm-3">
                        <div class="comment-meta d-flex">
                          <h6 class="me-2">Santiago Roberts</h6>
                          <span class="text-muted">4d</span>
                        </div>
                        <div class="comment-body">
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Iusto laborum in corrupti dolorum, quas delectus
                          nobis porro accusantium molestias sequi.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="justify-content-center">
                <div class="col-lg-8">
                  <div class="comment-form d-flex align-items-center">
                    <div class="flex-shrink-0">
                      <div class="avatar avatar-sm rounded-circle">
                        <img
                          class="avatar-img"
                          src="https://images.pexels.com/photos/91227/pexels-photo-91227.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200"
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="flex-grow-1 ms-2 ms-sm-3">
                      <form>
                        <textarea
                          class="form-control py-0 px-1 border-0"
                          rows="1"
                          onChange={(event) => {
                            this.setState({comment: event.target.value})
                          }}
                          placeholder="Start writing..."
                          style={{ resize: "none" }}
                        ></textarea>
                      </form>
                    </div>
                  
                  </div>
                </div>
                <div class="flex-grow-1 ms-2 ms-sm-3 mt-3">
                      <button
                        // href="#"
                        type="button"
                        class="btns"
                        onClick={this.onSubmit}
                      >
                        Submit
                      </button>
                    </div>
               
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Blog_inner;
