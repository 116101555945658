import React, { Component } from "react";
import joinusbanner from "../assets/v2/images/joinusbanner.png";
import developer from "../assets/v2/images/developer.svg";
import analytic from "../assets/v2/images/analytic.svg";
import compilence from "../assets/v2/images/compilence.svg";

export class JoinUs extends Component {
  render() {
    return (
      <section>
        <div class="hero_sec">
          <div class="container">
            <div class="row no-gutters align-items-center">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 ">
                <div class="detail">
                  <h1 style={{ marginTop: "100px", color: "#024AAD" }}>
                    Join us
                  </h1>
                  <p class="mt-4">
                    We believe that the future of decentralization lies with
                    people. Find your next job with us.
                  </p>

                  <a
                    href="javascript:void(0)"
                    onClickCapture={() =>
                      (window.location = "mailto:careers@shubh.network")
                    }
                    class="btns"
                  >
                    Submit Your Resume
                  </a>
                  {/* <a href="#intentSection" class="scroll_down"><img src="images/long_arrow_down.svg" alt=""></a> */}
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <img src={joinusbanner} alt="" />
              </div>
            </div>
          </div>
        </div>

        <section>
          <div class="built_sec">
            <div class="container">
              <div class="row specing17">
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <div class="built_block1">
                    <figure>
                      <img src={developer} alt="" />
                    </figure>
                    <div class="detail">
                      <h4 style={{ color: "#024AAD" }}>Developer</h4>
                      <p>
                        Professionals who understand Blockchain technology
                        profoundly and can build Blockchain-based applications
                        for businesses.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <div class="built_block1">
                    <figure>
                      <img src={analytic} alt="" />
                    </figure>
                    <div class="detail">
                      <h4 style={{ color: "#024AAD" }}>Analyst</h4>
                      <p>
                        Professionals who can examine business processes and
                        usage of these ledgers to determine the effectiveness of
                        the blockchain, and identify areas for improvement.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <div class="built_block1">
                    <figure>
                      <img src={compilence} alt="" />
                    </figure>
                    <div class="detail">
                      <h4 style={{ color: "#024AAD" }}>Compliance & Legal</h4>
                      <p>
                        Law professionals to implement the understanding of
                        Blockchain technology to speed up and simplify the
                        process of tracing digital audits and meeting legal
                        mandates.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    );
  }
}

export default JoinUs;
