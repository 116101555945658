import React, { Component } from "react";
// import our_team_banner from "../assets/v2/images/our_team_banner.png";
import ourteambanner_1 from "../assets/v2/images/ourteambanner_1.png";
import twitter from "../assets/v2/images/twitter.svg";
import LinkedIn from "../assets/v2/images/LinkedIn.svg";
import Instagram from "../assets/v2/images/Instagram.svg";
import user from "../assets/v2/images/user.png";
import v45_53 from "../assets/v2/images/v45_53.png";
import v45_59 from "../assets/v2/images/v45_59.png";
import v45_60 from "../assets/v2/images/v45_60.png";
import v45_61 from "../assets/v2/images/v45_61.jpeg";
import v45_62 from "../assets/v2/images/v45_62.png";
import v45_63 from "../assets/v2/images/v45_63.png";
import v45_32 from "../assets/v2/images/v45_32.png";
import v45_33 from "../assets/v2/images/v45_33.png";
import linkedin_disabled from "../assets/v2/images/linkedin_disabled.png";
import twitter_disable from "../assets/v2/images/twitter_disable.png";

export class Team extends Component {
  render() {
    return (
      <section>
        <div class="hero_sec">
          <div class="container">
            <div class="row no-gutters align-items-center">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 ">
                <div class="detail">
                  <h1 style={{ marginTop: "100px" }}>Our team</h1>
                  <p class="mt-4">
                    Shubh is being built to accommodate a broad range of use
                    cases, solving problems across multiple industry verticals.
                  </p>

                  {/* <a href="#" class="btns">Get started</a>  */}
                  <div class="clearfix"></div>
                  {/* <a href="#intentSection" class="scroll_down"><img src="images/long_arrow_down.svg" alt=""></a> */}
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <img src={ourteambanner_1} alt="" />
              </div>
            </div>
          </div>
        </div>

        {/* <div class="built_sec">
          <div class="container">
            <div class="row specing17">
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                <div class="built_block1">
                  <figure>
                    <img src={user} alt="" />
                  </figure>
                  <div class="detail">
                    <p style={{ color: "#000" }}>Faria Setiawan</p>
                    <p>Develoter</p>
                  </div>
                  <div className="row mt-4 ">
                    <img className="m-1" src={LinkedIn} alt="" />
                    <img className="m-1" src={twitter} alt="" />
                    <img className="m-1" src={Instagram} alt="" />
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                <div class="built_block1">
                  <figure>
                    <img src={user} alt="" />
                  </figure>
                  <div class="detail">
                    <p style={{ color: "#000" }}>Faria Setiawan</p>
                    <p>Develoter</p>
                  </div>
                  <div className="row mt-4 ">
                    <img className="m-1" src={LinkedIn} alt="" />
                    <img className="m-1" src={twitter} alt="" />
                    <img className="m-1" src={Instagram} alt="" />
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                <div class="built_block1">
                  <figure>
                    <img src={user} alt="" />
                  </figure>
                  <div class="detail">
                    <p style={{ color: "#000" }}>Faria Setiawan</p>
                    <p>Develoter</p>
                  </div>
                  <div className="row mt-4 ">
                    <img className="m-1" src={LinkedIn} alt="" />
                    <img className="m-1" src={twitter} alt="" />
                    <img className="m-1" src={Instagram} alt="" />
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                <div class="built_block1">
                  <figure>
                    <img src={user} alt="" />
                  </figure>
                  <div class="detail">
                    <p style={{ color: "#000" }}>Faria Setiawan</p>
                    <p>Develoter</p>
                  </div>
                  <div className="row mt-4 ">
                    <img className="m-1" src={LinkedIn} alt="" />
                    <img className="m-1" src={twitter} alt="" />
                    <img className="m-1" src={Instagram} alt="" />
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                <div class="built_block1">
                  <figure>
                    <img src={user} alt="" />
                  </figure>
                  <div class="detail">
                    <p style={{ color: "#000" }}>Faria Setiawan</p>
                    <p>Develoter</p>
                  </div>
                  <div className="row mt-4 ">
                    <img className="m-1" src={LinkedIn} alt="" />
                    <img className="m-1" src={twitter} alt="" />
                    <img className="m-1" src={Instagram} alt="" />
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                <div class="built_block1">
                  <figure>
                    <img src={user} alt="" />
                  </figure>
                  <div class="detail">
                    <p style={{ color: "#000" }}>Faria Setiawan</p>
                    <p>Develoter</p>
                  </div>
                  <div className="row mt-4 ">
                    <img className="m-1" src={LinkedIn} alt="" />
                    <img className="m-1" src={twitter} alt="" />
                    <img className="m-1" src={Instagram} alt="" />
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                <div class="built_block1">
                  <figure>
                    <img src={user} alt="" />
                  </figure>
                  <div class="detail">
                    <p style={{ color: "#000" }}>Faria Setiawan</p>
                    <p>Develoter</p>
                  </div>
                  <div className="row mt-4 ">
                    <img className="m-1" src={LinkedIn} alt="" />
                    <img className="m-1" src={twitter} alt="" />
                    <img className="m-1" src={Instagram} alt="" />
                  </div>
                </div>
              </div>

              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                <div class="built_block1">
                  <figure>
                    <img src={user} alt="" />
                  </figure>
                  <div class="detail">
                    <p style={{ color: "#000" }}>Faria Setiawan</p>
                    <p>Develoter</p>
                  </div>
                  <div className="row mt-4 ">
                    <img className="m-1" src={LinkedIn} alt="" />
                    <img className="m-1" src={twitter} alt="" />
                    <img className="m-1" src={Instagram} alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row specing17">
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                <div class="built_block1">
                  <figure>
                    <img src={user} alt="" />
                  </figure>
                  <div class="detail">
                    <p style={{ color: "#000" }}>Faria Setiawan</p>
                    <p>Develoter</p>
                  </div>
                  <div className="row mt-4 ">
                    <img className="m-1" src={LinkedIn} alt="" />
                    <img className="m-1" src={twitter} alt="" />
                    <img className="m-1" src={Instagram} alt="" />
                  </div>
                </div>
              </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                <div class="built_block1">
                  <figure>
                    <img src={user} alt="" />
                  </figure>
                  <div class="detail">
                    <p style={{ color: "#000" }}>Faria Setiawan</p>
                    <p >Develoter</p>
                  </div>
                  <div className="row mt-4 ">
                    <img className="m-1" src={LinkedIn} alt="" />
                    <img className="m-1" src={twitter} alt="" />
                    <img className="m-1" src={Instagram} alt="" />
                  </div>
                </div>
              </div>

              </div>
          </div>
        </div> */}

        <div class="intent_sec1">
          <div class="inner">
            <div class="row">
              <div class="col-sm-1"></div>
              <div class="col-lg-6 mt-5">
                <p style={{ color: "white", fontSize: "24px" }}>
                  Looking to join the team? Our careers Portal.
                </p>
              </div>
              <div class="col-lg-5 mt-5">
                <a href="/joinus" class="btns btns2">
                  Explore Careers
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="mgnt_section text-center">
          <div class="container">
            <h2 class="global_title text-center">Meet Our Managment</h2>
            <div class="row">
              <div class="col-lg-4 col-md-4 col-xl-4 col-sm-12">
                <a data-toggle="modal" data-target="#exampleModal">
                  <img class="mgnt_image" src={v45_53}></img>
                </a>
                <a data-toggle="modal" data-target="#exampleModal">
                  <p class="name_style">Dr. Neel Sawant</p>
                </a>
                <p class="position">Founder & Chief Executive Officer</p>
                <div style={{ flexDireztion: "row" }}>
                  <a href="https://www.linkedin.com/in/neel-sawant-a521a0241/">
                    <img src={v45_33} class="img_twitter"></img>
                  </a>
                  <a href="https://twitter.com/Neel__sawant">
                    <img src={v45_32} class="img_twitter"></img>
                  </a>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-xl-4 col-sm-12">
                <a data-toggle="modal" data-target="#exampleModal2">
                  <img class="mgnt_image" src={v45_59}></img>
                </a>
                <a data-toggle="modal" data-target="#exampleModal2">
                  <p class="name_style">John T. Crane</p>
                </a>
                <p class="position">Co-founder & Director</p>
                <div style={{ flexDireztion: "row" }}>
                  <a href="https://www.linkedin.com/in/john-crane-8b3b312/">
                    <img src={v45_33} class="img_twitter"></img>
                  </a>

                  <img src={twitter_disable} class="img_twitter"></img>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-xl-4 col-sm-12">
                <a data-toggle="modal" data-target="#exampleModal3">
                  <img class="mgnt_image" src={v45_60}></img>
                </a>
                <a data-toggle="modal" data-target="#exampleModal3">
                  <p class="name_style">Dr. Sarikonda Vinay</p>
                </a>
                <p class="name_style2">Kumar Reddy (SVR)</p>
                <p class="position2">SHUBH Ambassodar</p>
                <div style={{ flexDireztion: "row" }}>
                  <a href="https://www.linkedin.com/in/svr-sarikondavinaykumarreddy/">
                    <img src={v45_33} class="img_twitter"></img>
                  </a>
                  <a href="https://twitter.com/sarikonda_vinay">
                    <img src={v45_32} class="img_twitter"></img>
                  </a>
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-lg-4 col-md-4 col-xl-4 col-sm-12">
                <a data-toggle="modal" data-target="#exampleModal4">
                  <img class="mgnt_image" src={v45_61}></img>
                </a>
                <a data-toggle="modal" data-target="#exampleModal4">
                  <p class="name_style">Dr. Aditya Ganguli</p>
                </a>
                <p class="position">Chief Finance Officer</p>
                <div style={{ flexDireztion: "row" }}>
                  <img src={linkedin_disabled} class="img_twitter"></img>
                  <img src={twitter_disable} class="img_twitter"></img>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-xl-4 col-sm-12">
                <a data-toggle="modal" data-target="#exampleModal5">
                  <img class="mgnt_image" src={v45_62}></img>
                </a>
                <a data-toggle="modal" data-target="#exampleModal5">
                  <p class="name_style">Tais Gere</p>
                </a>
                <p class="position">Head of Business Development</p>
                <div style={{ flexDireztion: "row" }}>
                  <a href="https://www.linkedin.com/in/tais-djere/">
                    <img src={v45_33} class="img_twitter"></img>
                  </a>
                  <img src={twitter_disable} class="img_twitter"></img>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-xl-4 col-sm-12">
                <a data-toggle="modal" data-target="#exampleModal6">
                  <img class="mgnt_image" src={v45_63}></img>
                </a>
                <a data-toggle="modal" data-target="#exampleModal6">
                  <p class="name_style">Sandip R. Rane</p>
                </a>
                <p class="position">V.P Strategy</p>
                <div style={{ flexDireztion: "row" }}>
                  <a href="https://www.linkedin.com/in/sandip-rane-529487241/">
                    <img src={v45_33} class="img_twitter"></img>
                  </a>
                  <a href="https://twitter.com/sandip0385">
                    <img src={v45_32} class="img_twitter"></img>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog">
          <div class="modal-dialog custom_modal" role="document">
            <div class="modal-content" style={{ paddingBottom: "30px" }}>
              <div class="modal-header">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="container ">
                  <div class="row">
                    <div
                      class="col-md-6 col-lg-6 col-xl-6 col-sm-12"
                      style={{ marginTop: "95px", flexDirection: "row" }}
                    >
                      <div class="blue_line ml-2">
                        <p class="name_style ml-2">Dr. Neel Sawant</p>
                        <p class="ml-2">Founder & Chief Executive Officer</p>
                      </div>
                      <p>
                        PHD Blockchain Technologies- Application and Research
                        from EDU, President, DataBoss Inc. Leadership experience
                        in identifying innovative next-generation products and
                        solutions and undervalued companies with strategic
                        synergies that can be leveraged for growth in a
                        multi-billion dollar market.
                      </p>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 image_align">
                      <img src={v45_53} style={{ height: "360px" }}></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal fade" id="exampleModal2" tabindex="-1" role="dialog">
          <div class="modal-dialog custom_modal" role="document">
            <div class="modal-content" style={{ paddingBottom: "30px" }}>
              <div class="modal-header">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="container ">
                  <div class="row">
                    <div
                      class="col-md-6 col-lg-6 col-xl-6 col-sm-12"
                      style={{ marginTop: "95px" }}
                    >
                      <div class="blue_line ml-2">
                        <p class="name_style ml-2">John T. Crane</p>
                        <p class="ml-2">Co-founder & Director</p>
                      </div>
                      <p>
                        30+ years’ experience in real estate and real estate
                        finance extensively in the area of asset based lending
                        and issuance of letters of credit equipment. He is a
                        graduate of the highly acclaimed Hamburger University
                        program.
                      </p>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 image_align">
                      <img src={v45_59} style={{ height: "360px" }}></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal fade" id="exampleModal3" tabindex="-1" role="dialog">
          <div class="modal-dialog custom_modal" role="document">
            <div class="modal-content" style={{ paddingBottom: "30px" }}>
              <div class="modal-header">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="container ">
                  <div class="row">
                    <div
                      class="col-md-6 col-lg-6 col-xl-6 col-sm-12"
                      style={{ marginTop: "95px" }}
                    >
                      <div class="blue_line ml-2">
                        <p class="name_style ml-2">
                          Dr. Sarikonda Vinay Kumar Reddy (SVR)
                        </p>
                        <p class="ml-2">SHUBH Ambassodar</p>
                      </div>
                      <p>
                        Director India Operation DataBoss Inc, MD, Cardiology
                        from University of Maryland International Peace
                        Ambassador (United Nations Relations).
                      </p>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 image_align">
                      <img src={v45_60} style={{ height: "360px" }}></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal fade" id="exampleModal4" tabindex="-1" role="dialog">
          <div class="modal-dialog custom_modal" role="document">
            <div class="modal-content" style={{ paddingBottom: "30px" }}>
              <div class="modal-header">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="container ">
                  <div class="row">
                    <div
                      class="col-md-6 col-lg-6 col-xl-6 col-sm-12"
                      style={{ marginTop: "95px" }}
                    >
                      <div class="blue_line ml-2">
                        <p class="name_style ml-2">Dr. Aditya Ganguli</p>
                        <p class="ml-2">Chief Finance Officer</p>
                      </div>
                      <p>
                        An eminent Investment Banker. His career spans two
                        decades, with leading deployment of scalable financial
                        infrastructure Tools across industries with companies
                        like Infosys and SAP.
                      </p>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 image_align">
                      <img src={v45_61} style={{ height: "360px" }}></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal fade" id="exampleModal5" tabindex="-1" role="dialog">
          <div class="modal-dialog custom_modal" role="document">
            <div class="modal-content" style={{ paddingBottom: "30px" }}>
              <div class="modal-header">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="container ">
                  <div class="row">
                    <div
                      class="col-md-6 col-lg-6 col-xl-6 col-sm-12"
                      style={{ marginTop: "95px" }}
                    >
                      <div class="blue_line ml-2">
                        <p class="name_style ml-2">Tais Gere</p>
                        <p class="ml-2">Head of Business Development</p>
                      </div>
                      <p>
                        Director and founder of international operations for a
                        consulting agency in Dubai. 30 years experience
                        developing business for banks, insurance, electronics
                        and IT.
                      </p>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 image_align">
                      <img src={v45_62} style={{ height: "360px" }}></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal fade" id="exampleModal6" tabindex="-1" role="dialog">
          <div class="modal-dialog custom_modal" role="document">
            <div class="modal-content" style={{ paddingBottom: "30px" }}>
              <div class="modal-header">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="container ">
                  <div class="row">
                    <div
                      class="col-md-6 col-lg-6 col-xl-6 col-sm-12"
                      style={{ marginTop: "95px" }}
                    >
                      <div class="blue_line ml-2">
                        <p class="name_style ml-2">Sandip R. Rane</p>
                        <p class="ml-2">V.P Strategy</p>
                      </div>
                      <p>
                        BE Electronics, MBA Finance & Marketing Double Major
                        from New Hampshire College University USA. Professional
                        strategy management consultant experience for 20 years.
                      </p>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 image_align">
                      <img src={v45_63} style={{ height: "360px" }}></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Team;
