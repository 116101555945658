import React, { Component } from "react";
import long_arrow_down from "../assets/v2/images/long_arrow_down.svg";
import hero_image from "../assets/v2/images/hero_image.svg";
import mask_group_7 from "../assets/v2/images/mask_group_7.png";
import scalable from "../assets/v2/images/scalable.svg";
import secure from "../assets/v2/images/secure.svg";
import innovation_image from "../assets/v2/images/innovation_image.svg";
import industry_image from "../assets/v2/images/industry_image.svg";
import scalability from "../assets/v2/images/scalability.svg";
import smoothassets from "../assets/v2/images/smoothassets.png";
import proofstack from "../assets/v2/images/proofstack.svg";
import trustlesstransfer from "../assets/v2/images/trustlesstransfer.svg";
import speed from "../assets/v2/images/speed.svg";
import lowcost from "../assets/v2/images/lowcost.svg";
import finance from "../assets/v2/images/finance.svg";
import banking from "../assets/v2/images/banking.svg";
import logistic from "../assets/v2/images/logistic.svg";
import manufacturing from "../assets/v2/images/manufacturing.svg";
import supplychain from "../assets/v2/images/supplychain.svg";
import processed from "../assets/v2/images/processed.png";
import ecommerce from "../assets/v2/images/ecommerce.png";
import efficient_image from "../assets/v2/images/efficient_image.svg";
import efficient_icon1 from "../assets/v2/images/efficient_icon1.svg";
import efficient_icon2 from "../assets/v2/images/efficient_icon2.svg";
import $ from "jquery";
import shubh_white_paper from "../assets/shubh_white_paper.pdf";

import ReCAPTCHA from "react-google-recaptcha";

const recaptchaRef = React.createRef();

export class Home extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
    };
  }

  contactusfunction = () => {
    this.setState({ loading: true });

    const formData = new FormData();
    formData.append("email", this.state.email);

    const recaptchaValue = recaptchaRef.current.getValue();
    console.log("captcha", recaptchaValue);

    if (!recaptchaValue.length) {
      this.setState({ loading: false });
      alert("Please Check Captcha");
      return;
    }

    fetch("https://shubh.network/shubhbackend/api/subscribe", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ loading: false });
        if (data.success === true) {
          this.storeData(data.userid);
          this.storeEmail(this.state.email);
        } else {
          // toast(data.message);
        }
        alert("Thank You for subscribing");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  componentDidMount() {
    $(".scroll_down").click(function () {
      var sectionTo = $(this).attr("href");
      $("html, body").animate(
        {
          scrollTop: $(sectionTo).offset().top,
        },
        500
      );
    });
  }
  render() {
    return (
      <section>
        <div class="hero_sec">
          <div class="container">
            <div class="row no-gutters align-items-center">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div class="detail">
                  <h1>
                    Meet The Blockchain That <span>Works For You</span>.
                  </h1>
                  <p>
                    Built to create scalable decentralized application systems
                    for a multitude of business processes.
                  </p>
                  <a href="/buyshubh" class="btns">
                    Trade Shubh
                    {/* Download White Paper */}
                  </a>
                  <div class="clearfix"></div>
                  <a href="#intentSection" class="scroll_down">
                    <img src={long_arrow_down} alt="" />
                  </a>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <figure>
                  <img src={hero_image} alt="" />
                </figure>
              </div>
            </div>
          </div>
        </div>

        <div class="intent_sec" id="intentSection">
          <div class="container">
            <div class="inner">
              <div class="row">
                <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                  <h2>
                    Bringing Intent To The <br />
                    Ethereum Blockchain
                  </h2>
                  <p style={{ color: "white" }}>
                    Shubh combines the best practices of Plasma Sidechains with
                    current blockchain technologies. This enables us to build
                    enterprise grade software with a higher degree of security,
                    speed, and scalability beyond what is currently available.{" "}
                  </p>
                </div>
                <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                  <div class="row">
                    <div class="col-12">
                      <div class="intent">
                        {/* <h3>Scalable like never before </h3> */}
                        <div class="int_detail">
                          <figure class="black">
                            <img src={scalable} alt="" />
                          </figure>
                          <h4>Scalable like never Before.</h4>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="intent float-right">
                        {/* <h3>Asset and data automation</h3> */}
                        <div class="int_detail">
                          <figure>
                            <img src={secure} alt="" />
                          </figure>
                          <h4>Secure on Every Level</h4>
                        </div>
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="intent">
                        {/* <h3>Secure on every level</h3> */}
                        <div class="int_detail">
                          <figure class="black">
                            <img src={mask_group_7} alt="" />
                          </figure>
                          <h4>Asset and data Automation</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="welcome_sec">
          <div class="container">
            <div class="row flex-row-reverse align-items-center">
              <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                <div class="detail">
                  <h3>A New Era of Collaboration and Innovation.</h3>
                  <p>
                    Node selection enables a decentralized network of validators
                    to participate in the generation of blocks and the flow of
                    data between them. By collecting an organization's shared
                    transactional data from multiple sources we eliminate data
                    duplication and provide data integrity with a single source
                    of truth.
                  </p>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <figure>
                  <img src={innovation_image} alt="" />
                </figure>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-xl-7 col-lg-8 col-md-8 col-sm-12">
                <div class="detail">
                  <h3>A BlockChain Solution For Every Industry.</h3>
                  <p>
                    <span>
                      Shubh allows anyone to create scalable DApps while
                      ensuring superior user experience in a secure and
                      decentralized manner.
                    </span>{" "}
                    This is done by allowing cross-chain linking for leveraging
                    DApps on our side chain that gets secured through Plasma
                    chains. Thus, Shubh allows the creation of DApps that can
                    leverage its own network. The scalability allows us to
                    fulfill any of the upcoming generation's requirements.{" "}
                  </p>
                </div>
              </div>
              <div class="col-xl-5 col-lg-4 col-md-4 col-sm-12">
                <figure class="text-right">
                  <img src={industry_image} alt="" />
                </figure>
              </div>
            </div>
          </div>
        </div>

        <div class="built_sec">
          <div class="container">
            <h2 class="global_title">
              Built for the next generation <br />
              of business <span>challenges</span>.
            </h2>
            <div class="row specing17">
              <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div class="built_block">
                  <figure>
                    <img src={scalability} alt="" />
                  </figure>
                  <div class="detail">
                    <h3>Scalability</h3>
                    <p>
                      Enables the blockchain to scale infinitely and prevents
                      bloat in the main chain, which reduces its speed and cost
                      of transacting.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div class="built_block">
                  <figure>
                    <img src={smoothassets} alt="" />
                  </figure>
                  <div class="detail">
                    <h3>Smooth Asset Transfer</h3>
                    <p>
                      Allows participants to have their assets and identity on
                      multiple blockchains at once with no permission necessary
                      from anyone else.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div class="built_block">
                  <figure>
                    <img src={proofstack} alt="" />
                  </figure>
                  <div class="detail">
                    <h3>Proof-of-Stake Consensus</h3>
                    <p>
                      A Proof of Stake (PoS) consensus algorithm is like a
                      representative democracy where validators' stake in the
                      network decides which transactions will be processed.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div class="built_block">
                  <figure>
                    <img src={trustlesstransfer} alt="" />
                  </figure>
                  <div class="detail">
                    <h3>Trustless Transactions</h3>
                    <p>
                      Our API or client-side software application let's
                      merchants easily integrate with their current PoS systems
                      to accept cryptocurrency payments. Allowing them
                      information to all the transactions they have made.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div class="built_block">
                  <figure>
                    <img src={speed} alt="" />
                  </figure>
                  <div class="detail">
                    <h3>Speed</h3>
                    <p>
                      The transactions performed using Shubh sidechain are fast
                      and efficient. This is done by avoiding the need for
                      processing on a single chain and hence preventing bloat.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div class="built_block">
                  <figure>
                    <img src={lowcost} alt="" />
                  </figure>
                  <div class="detail">
                    <h3>Low Cost</h3>
                    <p>
                      Minimal hardware usage, low processing requirements on the
                      main chain, and bloat prevention allows Shubh to have
                      minuscule transaction fees.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="services_sec">
          <div class="container">
            <h2 class="global_title mb-3">
              Blockchain Development <br />
              <span>Services</span>
            </h2>
            <div class="row">
              <div class="col-12">
                <h3>Trustless Transactions</h3>
                <p>
                  Shubh's blockchain innovators are transforming industry around
                  the world with the powerful tools built specifically for job.
                </p>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <h3>Creation of dApps</h3>
                <p>
                  The Shubh platform will support the creation of DApps, making
                  it possible to create blockchain-based applications that are
                  not limited to a single blockchain, currency, or jurisdiction.
                  We act as a user's front-end interface while interacting with
                  the blockchain.{" "}
                </p>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <h3>Asset Transfer between Peers</h3>
                <p>
                  The platform builds upon Ethereum's smart contract system,
                  which means peers can transfer assets to other peers directly
                  with little to no transaction fees.
                </p>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <h3>Block Generation</h3>
                <p>
                  The Shubh platform will directly support the generation of
                  blocks. When a block is generated, it is sent to all peers,
                  who validate it and apply state transitions only when
                  validation succeeds. state transition requests occur within a
                  few seconds.
                </p>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <h3>Asset Security</h3>
                <p>
                  Your digital assets will be secure as a copy of the entire
                  ledger will be stored on each peer, enabling the platform to
                  provide security for assets and transactions.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="intent_sec" id="intent_section">
          <div>
            <div class="inner ">
              <div class="row">
                <div class="col-sm-8">
                  <div class="get_info ">
                    <h2>Get started with Shubh Network </h2>
                    <p>
                      Join an existing network. Build your own blockchain
                      solution. Co-create one with our experts. Wherever you are
                      in your blockchain journey, we will meet you there and get
                      you to the next step.{" "}
                    </p>
                  </div>
                </div>

                <div class="col-sm-4 " style={{ marginTop: "120px" }}>
                  <a href="/implemetation5" class="btns btns2">
                    Explore Blockchain Solutions
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="solve_sec">
          <div class="container">
            <h2 class="global_title mb-3">
              <small>The Shubh Network</small> The size and scale to help solve
              all your <br />
              <span>business challenges</span>
            </h2>
            <h3>
              Redefining Industries with Shubh's Blockchain Application
              Development Services
            </h3>
            <div class="info">
              Our blockchain application development platform serves different
              industries and enhances organization efficiency by adopting
              emerging blockchain technologies.
            </div>
            <div class="row specing17">
              <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div class="built_block">
                  <figure>
                    <img src={finance} alt="" />
                  </figure>
                  <div class="detail">
                    <h3>Finance</h3>
                    <p>
                      From withdrawal, transfers to lending, and funding, a wide
                      range of services that have multiple complex processes
                      could be primary beneficiaries of Shubh's blockchain
                      application development services.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div class="built_block">
                  <figure>
                    <img src={banking} alt="" />
                  </figure>
                  <div class="detail">
                    <h3>Banking</h3>
                    <p>
                      Banking is one of the services which involve a higher
                      degree of complex functionalities. Shubh's blockchain
                      solutions can significantly reduce this intricacy as the
                      entire progress can be managed through decentralized
                      solutions.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div class="built_block">
                  <figure>
                    <img src={logistic} alt="" />
                  </figure>
                  <div class="detail">
                    <h3>Logistics</h3>
                    <p>
                      Our blockchain application provides transparent solutions
                      for, warehousing, and responsible sourcing. Building trust
                      from source to consumer. Foster greater trust and
                      collaboration in products and processes.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div class="built_block">
                  <figure>
                    <img src={manufacturing} alt="" />
                  </figure>
                  <div class="detail">
                    <h3>Manufacturing</h3>
                    <p>
                      The manufacturing industry is known for streamlined
                      processes that need a significant amount of accuracy in
                      product delivery. Well, Shubh's elite decentralized
                      applications can create established documentation of
                      quality checks and also reduces the need for audits by
                      original equipment manufacturers.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div class="built_block">
                  <figure>
                    <img src={ecommerce} alt="" />
                  </figure>
                  <div class="detail">
                    <h3>E-Commerce</h3>
                    <p>
                      High data volume from various domains can create
                      inconvenience in the information is managed. Shubh's
                      blockchain solution allows users to securely store and
                      share information related to payment processing, product
                      searches, purchases, and more.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div class="built_block">
                  <figure>
                    <img src={supplychain} alt="" />
                  </figure>
                  <div class="detail">
                    <h3>Supply chain</h3>
                    <p>
                      Our services can implement more transparent and precise
                      tracking in the supply chain environment. In addition,
                      businesses can digitize their physical assets and create
                      decentralized documentation of all transactions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="analyst_sec">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-xl-8 col-lg-8 col-md-7 col-sm-12">
                <div class="detail">
                  <h2>
                    Get analyst research on blockchain adoption within
                    industries.
                  </h2>
                  <p>
                    Which industries are getting the most for their blockchain
                    investment and why? Talk to experts in industrial markets
                    and compare blockchain solutions currently offered.
                  </p>
                  <a
                    href={shubh_white_paper}
                    class="btns h_white"
                    target="_blank"
                    download
                  >
                    Download Whitepaper
                  </a>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-5 col-sm-12">
                <img src={processed} />
              </div>
            </div>
          </div>
        </div>

        <div class="efficient_sec">
          <div class="container">
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 align-self-center">
                <h2 class="global_title mb-3">
                  Ethereum Made <br />
                  <span>Efficient.</span>
                </h2>
                <h3>
                  Outcome-Driven Network Design That Enable Blockchains to
                  Deliver Results
                </h3>
                <p>
                  The Shubh platform will use a Proof-of-Stake (PoS) system to
                  build and verify blocks. The PoS system will require the
                  generation of blocks from approved transactions and the
                  resulting staking of SHUBH tokens. This process ensures
                  decentralization and also enables real-time validation of
                  transaction requests and state transitions, improving block
                  generation time and throughput.
                </p>
                <p>
                  Shubh's unique design and development of applications
                  accomplishing specific business goals benefit two different
                  kinds of users.
                </p>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <figure>
                  <img src={efficient_image} alt="" />
                </figure>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div class="effic">
                  <i>
                    <img src={efficient_icon1} alt="" />
                  </i>
                  <h3>Business Owners</h3>
                  <p>
                    Businesses will be able to utilize blockchain technology for
                    seamless transactions without having to worry about the long
                    wait times and high transaction costs. This should
                    facilitate faster exchanges which were not possible at scale
                    before.
                  </p>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div class="effic">
                  <i>
                    <img src={efficient_icon2} alt="" />
                  </i>
                  <h3>Investors</h3>
                  <p>
                    Investors will be able to use their cryptocurrencies within
                    the Shubh ecosystem for trading. The PoS system to make
                    profitable trades via faster cheaper crypto transactions.
                    This is possible while still maintaining its decentralized
                    features.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="newsletter_sec">
          <div class="container">
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div class="detail">
                  <p>
                    <span>
                      Shubh leverages advanced technology in blockchain
                      validation for more secure transactions in its network
                      than many of its peers.
                    </span>
                  </p>
                  <p>
                    <strong>
                      Multiple revolutionary blockchain benefits lead to Shubh
                      being an incredible and obvious choice for developing
                      dApps.
                    </strong>
                  </p>
                  <p>
                    Combining a well-designed security model with PoS validation
                    will ensure that Shubh remains secure at any scale of
                    operation.
                  </p>
                  <p>
                    A thorough approach in developing their protocol with a
                    robust security model that gives PoS validators the ability
                    to check whether transactions are valid and likely to be
                    executed
                  </p>
                  <p>
                    Proof-of-Work requires miners to use expensive hardware,
                    increased electricity costs. Shubh Network reduces energy
                    costs significantly, which allows for more efficient
                    hardware and faster processing.{" "}
                  </p>
                  <p>
                    Shubh will be interoperable with other platforms and allow
                    for the ability to use blockchain platforms and protocols,
                    such as Ethereum, Rootstock, and Qtum.
                  </p>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div class="news_form">
                  <h2 class="global_title white">Newsletter</h2>
                  <p style={{ color: "white" }}>
                    Subscribe to our newsletter and get all the latest crypto
                    news, updates, community trends, and reports.
                  </p>
                  <input
                    type="text"
                    class="textinput mt-3"
                    placeholder="Enter your email address"
                    name="emil"
                    onChange={(event) =>
                      this.setState({ email: event.target.value })
                    }
                  ></input>

                  {/* <a href="#" class="btns white">
                    Subscribe
                  </a> */}
                  <ReCAPTCHA
                    sitekey="6Ld0HmsgAAAAAIaI3ZchWCm4f7n4WUEmY5wrx1lR"
                    ref={recaptchaRef}
                    className="mt-3"
                  />
                  <button
                    // href="#"
                    type="button"
                    class="btns white"
                    onClick={this.contactusfunction}
                  >
                    Subscribe
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="get_start">
          <div class="container">
            <div class="row " style={{ textAlign: "left" }}>
              <div class="col-xl-7 col-lg-7 col-md-6 col-sm-12">
                <h2 class="global_title white">Be an early adopter</h2>
                <p>
                  Early movers gain an advantage, join our network now to
                  maximize your returns.
                </p>
              </div>

              <div class="col-xl-5 col-lg-5 col-md-6 col-sm-12">
                <div class="row">
                  <input
                    type="text"
                    class="textinput1 mt-3"
                    placeholder="Enter your email address"
                  ></input>

                  <a
                    href="javascript:void(0)"
                    onClick={() =>
                      alert("Thank you! We will contact you soon.")
                    }
                  >
                    <div
                      class="mt-3 "
                      style={{
                        backgroundColor: "#17181C",
                        height: "50px",
                        width: "100px",
                      }}
                    >
                      <p
                        style={{
                          fontSize: 16,
                          padding: "3px",
                          textAlign: "center",
                        }}
                      >
                        Get in Touch
                      </p>
                    </div>
                  </a>

                  {/* <div
                    onClick="/signup"
                    class="mt-3 "
                    style={{
                      backgroundColor: "#17181C",
                      height: "50px",
                      width: "100px",
                    }}
                  >
                    <p
                      style={{
                        alignSelf: "center",
                        marginLeft: "12px",
                        marginTop: "5px",
                      }}
                    >
                      Sign Up
                    </p>
                  </div> */}
                </div>
              </div>
              {/* <a href="#" class="btns btns2">
              Explore Blockchain Solutions
            </a> */}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Home;
