import React, { Component } from "react";
import implementation1 from "../assets/v2/images/implementation1.png";
import implementation2 from "../assets/v2/images/implementation2.png";

class Implemetation5 extends Component {
  render() {
    return (
      <section>
        <div class="hero_sec">
          <div class="container">
            <div class="row no-gutters align-items-center">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 ">
                <div class="detail">
                  <h1 style={{ marginTop: "100px" }}>Features</h1>
                  <p>
                    Shubh Transforming Businesses for a Better Tomorrow.
                    Accelerate your journey towards blockchain.
                  </p>
                  {/* <a href="#" class="btns">Get started</a>  */}
                  <div class="clearfix"></div>
                  {/* <a href="#intentSection" class="scroll_down"><img src="images/long_arrow_down.svg" alt=""></a> */}
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <img src={implementation1} alt="" />
              </div>
            </div>
          </div>
        </div>

        <div class="hero_sec">
          <div class="container">
            <div class="row">
              <div class="col-sm-3"></div>
              <div class="col-md-6 text-center">
                <h5 style={{ fontSize: "20px" }}>E-commerce Marketplace</h5>
                <p style={{ fontSize: "16px" }}>
                  Shubh will provide an E-commerce marketplace to allows users
                  to buy and sell services using Shubh tokens.
                </p>
              </div>
              <div class="col-sm-3"></div>

              <div class="col-sm-3" style={{ textAlign: "right" }}>
                <h5 style={{ fontSize: "20px" }}>DApps Development</h5>
                <p style={{ fontSize: "16px" }}>
                  Allow users to build DApps that have real-world utility and
                  allow assets of value (NFTs) to circulate freely amongst
                  individuals across all levels of society
                </p>
              </div>
              <div class="col-md-6 text-center" style={{marginTop: '-30px'}}>
                <img src={implementation2} style={{ height: "470px" }} />
              </div>
              <div class="col-sm-3" style={{ textAlign: "left" }}>
                <h5 style={{ fontSize: "20px" }}>Asset Exchange</h5>
                <p style={{ fontSize: "16px" }}>
                  Inspired by the rapid growth of the cryptocurrency market,
                  Shubh will provide instantaneous digital asset exchange for
                  assets in a decentralized peer-to-peer (P2P) environment.
                </p>
              </div>

        <div class="col-sm-1"></div>
              <div class="col-sm-4" style={{textAlign:'right'}}>
                <h5 style={{ fontSize: "20px" }}>Token Sales</h5>
                <p style={{ fontSize: "16px" }}>
                  A simple and robust platform for conducting token sales that
                  utilizes smart contracts for escrow, vesting and control.
                </p>
              </div>
              <div class="col-sm-2"></div>
              <div class="col-sm-4">
                <h5 style={{ fontSize: "20px" }}>NFT Marketplace</h5>
                <p style={{ fontSize: "16px" }}>
                  Shubh’s NFT marketplace will empower creators with a new
                  medium for selling their digital assets including brand
                  exposure, product placements, and revenue streams.
                </p>
              </div>
              <div class="col-sm-1"></div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Implemetation5;
