import React, { Component } from "react";
import footer_logo from "../assets/v2/images/footer_logo.svg";
import Forum from "./Forum";
import Blog_detail from "../components/Blog_detail";
import Blog2 from "../components/Blog2";
import Blog_inner from "./Blog_inner";
import ContactUs from "./ContactUs";
import AboutUs from "./AboutUs";
import Implementation5 from "./Implementation5";
import shubh_white_paper from "../assets/shubh_white_paper.pdf";
import Login from "./Login";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
export class Footer extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>

          <Route path="/contact">
            <ContactUs />
          </Route>

          <Route path="/aboutus">
            <AboutUs />
          </Route>
          <Route path="/blog_inner">
            <Blog_inner />
          </Route>
          <Route path="/blog_cover">
            <Blog2 />
          </Route>
          <Route path="/blog_detail">
            <Blog_detail />
          </Route>
          <Route path="/implementation5">
            <Implementation5 />
          </Route>
          <Route path="/forum">
            <Forum />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
        </Switch>
        <footer>
          <div class="footer_detail">
            <div class="container">
              <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                  <figure class="footer_logo">
                    <img src={footer_logo} alt="" />
                  </figure>
                  <p>
                    Shubh is a protocol and a framework for building <br />
                    and connecting Ethereum-compatible <br />
                    blockchain networks
                  </p>
                  <ul class="social_info">
                    <li>
                      <a href="https://t.me/Shubh_Network">
                        <i class="fa fa-telegram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/NetworkShubh">
                        <i class="fa fa-twitter"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                  {/* <h3>General</h3> */}
                  <h4 style={{ color: "white", fontSize: 20 }}>Quick Links</h4>
                  <ul class="footer_link">
                    <li>
                      <a href="/aboutus">About Us</a>
                    </li>
                    <li>
                      <a href="/joinus">Careers</a>
                    </li>
                    {/* <li>
                      <a href="/forum">forum</a>
                    </li> */}
                    {/* <li>
                      <a href="/login">Login</a>
                    </li> */}
                  </ul>
                </div>

                <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12 mt-4">
                  <h4 style={{ color: "white", fontSize: 20 }}></h4>
                  <ul class="footer_link">
                    <li>
                      <a href={shubh_white_paper} target="_blank" download>
                        Whitepaper
                      </a>
                    </li>
                    <li>
                      <a href="https://ethereum.org/en/">Ethereum</a>
                    </li>
                    <li>
                      <a href="/contact">Contact Us</a>
                    </li>
                  </ul>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12"></div>
              </div>
            </div>
          </div>

          <div class="copyright text-center">
            <div class="container">
              <p>&copy; 2022 Shubh Network Limited</p>
            </div>
          </div>
        </footer>
      </Router>
    );
  }
}

export default Footer;
