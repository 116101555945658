import React, { Component, Suspense } from "react";
import aboutusbanner from "../assets/v2/images/aboutusbanner.png";
import aboutus2 from "../assets/v2/images/aboutus2.png";

export class AboutUs extends Component {
  render() {
    return (
      <section>
        <div class="hero_sec">
          <div class="container">
            <div class="row no-gutters align-items-center">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 ">
                <div class="detail">
                  <h1 style={{ marginTop: "100px" }}>About Us</h1>
                  <p>
                    At Shubh, we strive to make the most important part of the
                    financial world decentralized
                  </p>
                  {/* <a href="#" class="btns">Get started</a>  */}
                  <div class="clearfix"></div>
                  {/* <a href="#intentSection" class="scroll_down"><img src="images/long_arrow_down.svg" alt=""></a> */}
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <img src={aboutusbanner} alt="" />
              </div>
            </div>
          </div>
        </div>

        <div class="hero_sec">
          <div class="container">
            <div class="row no-gutters align-items-center">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <img src={aboutus2} alt="" />
              </div>

              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 ">
                <div class="detail">
                  <h3 style={{ marginTop: "100px" }}> Shubh’s vision</h3>
                  <p style={{fontSize:'18px'}}>
                    We aim to launch “Shubh” as a next generation blockchain
                    system and strive to build a decentralized future by using
                    innovative technologies and techniques to address the
                    scalability problem of Ethereum and other public
                    blockchains. This will open doors to new opportunities,
                    create incentives for people to use them, and initiate a
                    community of support. With the technology and the support of
                    our team, Shubh can create a new and better financial world.
                  </p>
                  {/* <a href="#" class="btns">Get started</a>  */}
                  <div class="clearfix"></div>
                  {/* <a href="#intentSection" class="scroll_down"><img src="images/long_arrow_down.svg" alt=""></a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default AboutUs;
