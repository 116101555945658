import React, { Component } from "react";
import blog_banner from "../assets/v2/images/blog_banner.png";

export class Blog2 extends Component {

  
  render() {
   
    return (
      <section>
        <div class="hero_sec">
          <div class="container">
            <div class="row no-gutters align-items-center">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div class="detail">
                  <h1>Blog</h1>
                  <p style={{ fontSize: "18px" }}>
                    Shubh is being built to accommodate a broad range of use
                    cases, solving problems across multiple industry verticals.
                  </p>
                  {/* <a href="#" class="btns">
                    Get started
                  </a> */}
                  <div class="clearfix"></div>
                  {/* <a href="#intentSection" class="scroll_down">
                    <img src={long_arrow_down} alt="" />
                  </a> */}
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <img src={blog_banner} alt="" />
              </div>
            </div>
          </div>
        </div>

        <div class="built_sec">
          <div class="container">
            {/* <h2 class="global_title">Built for the next generation <br>of business <span>challenges</span>.</h2>  */}
            <div class="row specing17">
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                <a href="/blog_inner">
                  <div className="backgroundimage">
                    <p className="fontsize">Faria Setiawan</p>
                    <h6 className="smallfontsize">Develoter</h6>
                  </div>
                </a>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                <a href="/blog_inner">
                  <div className="backgroundimage">
                    <p className="fontsize">Faria Setiawan </p>
                    <h6 className="smallfontsize">Develoter </h6>
                  </div>
                </a>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                <a href="/blog_inner">
                  <div className="backgroundimage">
                    <p className="fontsize">Faria Setiawan</p>
                    <h6 className="smallfontsize">Develoter</h6>
                  </div>
                </a>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                <a href="/blog_inner">
                  <div className="backgroundimage">
                    <p className="fontsize">Faria Setiawan</p>
                    <h6 className="smallfontsize">Develoter</h6>
                  </div>
                </a>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                <a href="/blog_inner">
                  <div className="backgroundimage">
                    <p className="fontsize">Faria Setiawan</p>
                    <h6 className="smallfontsize">Develoter</h6>
                  </div>
                </a>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                <a href="/blog_inner">
                  <div className="backgroundimage">
                    <p className="fontsize">Faria Setiawan</p>
                    <h6 className="smallfontsize">Develoter</h6>
                  </div>
                </a>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                <a href="/blog_inner">
                  <div className="backgroundimage">
                    <p className="fontsize">Faria Setiawan</p>
                    <h6 className="smallfontsize">Develoter</h6>
                  </div>
                </a>
              </div>

              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                <a href="/blog_inner">
                  <div className="backgroundimage">
                    <p className="fontsize">Faria Setiawan</p>
                    <h6 className="smallfontsize">Develoter</h6>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Blog2;
