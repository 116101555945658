// import logo from './logo.svg';
// import './App.css';
// import './assets/css/bootstrap.min.css';
// import './assets/css/font-awesome.min.css';
// import './assets/css/magnific-popup.css';
// import './assets/css/blue.css';


import Header from "./components/Header2.js";
import Footer from "./components/Footer2.js";
// import Home from "./components/Home2.js";

// import Footer from "./components/Footer.js";
// import Header from "./components/Header.js";
// import Footer from "./components/Footer.js";
// import Home from "./components/Home.js";

function App() {
  return (
    <div className="App">
      <Header></Header>
      {/* <Home></Home> */}
      <Footer></Footer>

    </div>
  );
}

export default App;
