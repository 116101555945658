import React, { Component } from "react";

import Contactusbanner from "../assets/v2/images/Contactusbanner.png";
import ReCAPTCHA from "react-google-recaptcha";
// import * as Constants from "../Constants";
import ClipLoader from "react-spinners/ClipLoader";

const recaptchaRef = React.createRef();

export class ContactUs extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      number: "",
      address: "",
      message: "",
      loading: false,

      nameError: null,
      emailError: null,
      numberError: null,
      addressError: null,
      messageError: null,
    };
  }

  validate() {
    let nameError = "";
    let emailError = "";
    let numberError = "";
    let addressError = "";
    let messageError = "";

    if (!this.state.name) {
      nameError = "Your name cannot be empty";
    }
    if (!this.state.email) {
      emailError = "Your email cannot be empty";
    }
    if (!this.state.number) {
      numberError = "Your number cannot be empty";
    }
    if (!this.state.address) {
      addressError = "Your address cannot be empty";
    }
    if (!this.state.message) {
      messageError = "Your message cannot be empty";
    }

    if (
      nameError ||
      emailError ||
      numberError ||
      addressError ||
      messageError
    ) {
      this.setState({
        nameError,
        emailError,
        numberError,
        addressError,
        messageError,
      });
      return false;
    }
    return true;
  }

  storeData = (value) => {
    try {
      localStorage.setItem("userId", value);
      console.log("userId", value);
    } catch (e) {
      // saving error
    }
  };

  onSubmit = async () => {
    if (this.validate()) {
      this.contactusfunction();
    }
  };

  contactusfunction = async () => {
    this.setState({ loading: true });

    const formData = new FormData();
    formData.append("name", this.state.name);
    formData.append("email", this.state.email);
    formData.append("number", this.state.number);
    formData.append("address", this.state.address);
    formData.append("message", this.state.message);

    const recaptchaValue = recaptchaRef.current.getValue();
    console.log("captcha", recaptchaValue);

    if (!recaptchaValue.length) {
      this.setState({ loading: false });
      alert("Please Check Captcha");
      return;
    }

    fetch("https://shubh.network/shubhbackend/api/sendemail", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ loading: false });
        if (data.success === true) {
          this.storeData(data.userid);
          this.storeEmail(this.state.email);
          // toast(data.message);
        } else {
          // toast(data.message);
        }
        alert("Email sent successfully we will contact you soon");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  render() {
    return (
      <section>
        {this.state.loading ? (
          <div class="text-center">
            <ClipLoader size={150} />
          </div>
        ) : (
          <section>
            <div class="hero_sec">
              <div class="container">
                <div class="row no-gutters align-items-center">
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 ">
                    <div class="detail">
                      <h1 style={{ marginTop: "100px" }}>
                        Contact us
                        <p>We usually address queries within 24 hours.</p>
                      </h1>
                      {/* <a href="#" class="btns">Get started</a>  */}
                      <div class="clearfix"></div>
                      {/* <a href="#intentSection" class="scroll_down"><img src="images/long_arrow_down.svg" alt=""></a> */}
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <img src={Contactusbanner} alt="" />
                  </div>
                </div>
              </div>
            </div>

            <section class="services_sec">
              <form>
                <div className="row ">
                  <div class="col-sm-2"></div>
                  <div class="col-sm-8">
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="form-group">
                          <input
                            className="input form-control"
                            type="text"
                            name="name"
                            onChange={(event) =>
                              this.setState({ name: event.target.value })
                            }
                            required
                            aria-describedby="emailHelp"
                            placeholder="Name"
                          />
                          {!!this.state.nameError && (
                            <text style={{ color: "red" }}>
                              {this.state.nameError}
                            </text>
                          )}
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                          <input
                            className="input form-control"
                            type="text"
                            name="email"
                            onChange={(event) =>
                              this.setState({ email: event.target.value })
                            }
                            aria-describedby="emailHelp"
                            placeholder="Email"
                          />
                          {!!this.state.emailError && (
                            <text style={{ color: "red" }}>
                              {this.state.emailError}
                            </text>
                          )}
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                          <input
                            type="text"
                            name="number"
                            onChange={(event) =>
                              this.setState({ number: event.target.value })
                            }
                            className="input form-control"
                            aria-describedby="emailHelp"
                            placeholder="Number"
                          />
                          {!!this.state.numberError && (
                            <text style={{ color: "red" }}>
                              {this.state.numberError}
                            </text>
                          )}
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                          <input
                            type="text"
                            name="address"
                            onChange={(event) =>
                              this.setState({ address: event.target.value })
                            }
                            className="input form-control"
                            aria-describedby="emailHelp"
                            placeholder="Address"
                          />
                          {!!this.state.addressError && (
                            <text style={{ color: "red" }}>
                              {this.state.addressError}
                            </text>
                          )}
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="form-group">
                          <textarea
                            type="text"
                            name="message"
                            onChange={(event) =>
                              this.setState({ message: event.target.value })
                            }
                            className="textarea form-control"
                            aria-describedby="emailHelp"
                            placeholder="Message"
                          />
                          {!!this.state.messageError && (
                            <text style={{ color: "red" }}>
                              {this.state.messageError}
                            </text>
                          )}
                        </div>
                      </div>
                    </div>
                    <ReCAPTCHA
                      sitekey="6Ld0HmsgAAAAAIaI3ZchWCm4f7n4WUEmY5wrx1lR"
                      ref={recaptchaRef}
                      className="mb-5"
                    />
                    <div class="col-sm-2"></div>
                    <div className="col-lg-6 col-xl-6 col-sm-12 col-md-6 offset-3">
                      <button
                        // href="#"
                        type="button"
                        class="btns"
                        onClick={this.onSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </section>
          </section>
        )}
      </section>
    );
  }
}

export default ContactUs;
