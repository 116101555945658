import React, { Component } from "react";

import ReCAPTCHA from "react-google-recaptcha";

const recaptchaRef = React.createRef();

class SignUp extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      password: "",
      c_password: "",

      nameError: null,
      emailError: null,
      passwordError: null,
      c_passwordError: null,
    };
  }

  validate() {
    let nameError = "";
    let emailError = "";
    let passwordError = "";
    let c_passwordError = "";

    if (!this.state.name) {
      nameError = "Your name cannot be empty";
    }
    if (!this.state.email) {
      emailError = "Your email cannot be empty";
    }
    if (!this.state.password) {
      passwordError = "Your password cannot be empty";
    }
    if (!this.state.c_password) {
      c_passwordError = "Your confirm password cannot be empty";
    } else {
      if (this.state.password !== this.state.c_password) {
        c_passwordError = "Confirm Password does not match";
      }
    }

    if (nameError || emailError || passwordError || c_passwordError) {
      this.setState({
        nameError,
        emailError,
        passwordError,
        c_passwordError,
      });
      return false;
    }
    return true;
  }

  onSubmit = async () => {
    if (this.validate()) {
      this.registerfunction();
    }
  };

  registerfunction = () => {
    this.setState({ loading: true });

    const formData = new FormData();
    formData.append("name", this.state.name);
    formData.append("email", this.state.email);
    formData.append("password", this.state.password);
    formData.append("c_password", this.state.c_password);

    const recaptchaValue = recaptchaRef.current.getValue();
    console.log("captcha", recaptchaValue);

    if (!recaptchaValue.length) {
      this.setState({ loading: false });
      alert("Please Check Captcha");
      return;
    }

    fetch("https://shubh.network/shubhbackend/api/register", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ loading: false });
        if (data.success === true) {
          this.storeData(data.userid);
        } else {
          // toast(data.message);
        }
        alert("Thank You for Register");
        window.location.href = "/login";
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  render() {
    return (
      <section>
        <div class="hero_sec">
          <form>
            <div className="row ">
              <div class="col-sm-2"></div>
              <div class="col-sm-8">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <input
                        className="input form-control"
                        type="text"
                        name="name"
                        onChange={(event) =>
                          this.setState({ name: event.target.value })
                        }
                        required
                        aria-describedby="emailHelp"
                        placeholder="Name"
                      />
                      {!!this.state.nameError && (
                        <text style={{ color: "red" }}>
                          {this.state.nameError}
                        </text>
                      )}
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <input
                        className="input form-control"
                        type="text"
                        name="email"
                        onChange={(event) =>
                          this.setState({ email: event.target.value })
                        }
                        aria-describedby="emailHelp"
                        placeholder="Email"
                      />
                      {!!this.state.emailError && (
                        <text style={{ color: "red" }}>
                          {this.state.emailError}
                        </text>
                      )}
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-group">
                      <input
                        type="password"
                        name="password"
                        onChange={(event) =>
                          this.setState({ password: event.target.value })
                        }
                        className="input form-control"
                        aria-describedby="emailHelp"
                        placeholder="password"
                      />
                      {!!this.state.passwordError && (
                        <text style={{ color: "red" }}>
                          {this.state.passwordError}
                        </text>
                      )}
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-group">
                      <input
                        type="password"
                        name="c_password"
                        onChange={(event) =>
                          this.setState({ c_password: event.target.value })
                        }
                        className="input form-control"
                        //   aria-describedby="emailHelp"
                        placeholder="Comfirm Password"
                      />
                      {!!this.state.c_passwordError && (
                        <text style={{ color: "red" }}>
                          {this.state.c_passwordError}
                        </text>
                      )}
                    </div>
                  </div>
                </div>

                <ReCAPTCHA
                  sitekey="6Ld0HmsgAAAAAIaI3ZchWCm4f7n4WUEmY5wrx1lR"
                  ref={recaptchaRef}
                  className="mb-5"
                />
                <div class="col-sm-2"></div>

                <div className="col-lg-6 col-xl-6 col-sm-12 col-md-6 offset-3">
                  <button
                    // href="#"
                    type="button"
                    class="btns"
                    onClick={this.onSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    );
  }
}

export default SignUp;
