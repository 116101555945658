import React, { Component, useEffect } from "react";
import blogbanner from "../assets/v2/images/blogbanner.png";
export class Forum extends Component {
  constructor() {
    super();
    this.state = { blog: [] };
    this.getlist();
  }

  getlist = () => {
    this.setState({ loading: true });

    fetch("https://shubh.network/shubhbackend/api/blog", {
      method: "GET",
      // body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ loading: false });
        if (data.status === "success") {
          this.setState({
            blog: data.blogs,
          });
          console.log(this.state.blog);
          // this.storeData(data.userid);
          // this.storeEmail(this.state.email);
          // toast(data.message);
        } else {
          // toast(data.message);
        }
        // alert('Email sent successfully we will contact you soon')
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  renderPost = () => {
    return this.state.blog.map((data) => (
      <a href={"/blog_inner?id=" + data.id}>
        <div class="card">
          <figure>
            <img src={data.feature_image} alt="" style={{ height: "220px" }} />
          </figure>
          {/* {blog.map(item => (           
                  <h6>{item.title}</h6>
                ))} */}
          <h6>{data.title}</h6>
          {/* {parse(data.content)} */}

          {/* <p>Develoter &nbsp; March,2021</p> */}
        </div>
      </a>
    ));
  };
  render() {
    const { blog } = this.state;

    return (
      <section>
        <div class="hero_sec">
          <div class="container">
            <div class="row no-gutters align-items-center">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div class="detail">
                  <h1>News</h1>
                  <p>
                    Shubh is being built to accommodate a broad range of use
                    cases, solving problems across multiple industry verticals.
                  </p>

                  <div class="clearfix"></div>
                  {/* <a href="#intentSection" class="scroll_down">
                    <img src={long_arrow_down} alt="" />
                  </a> */}
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <img src={blogbanner} alt="" />
              </div>
            </div>
          </div>
        </div>

        {/* <div class="blog_list">
          <div class="container">
            <div class="card-columns blog-card-columns">
              {this.renderPost()}
            </div>
          </div>
        </div> */}
      </section>
    );
  }
}
export default Forum;
